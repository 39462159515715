<template>
  <div class="container_con" style="background: none;">
    <el-card style="margin-bottom: 15px;" v-if="data">
      <!-- <div class="con_title"><span></span> 采购计划</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{purchase_plan.purchase_plan_date}}</el-col>
              <el-col :span="6">联系电话：{{purchase_plan.merchant.mebl_operation_mode}}</el-col>
              <el-col :span="6">部门：{{purchase_plan.department_name}}</el-col>
              <el-col :span="6">供货单位：{{purchase_plan.merchant.merchant_name}}</el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="6">单据编号：{{purchase_plan.purchase_plan_no}}</el-col>
              <el-col :span="6">单位编号：{{purchase_plan.merchant.merchant_no}}</el-col>
              <el-col :span="6">采购员：{{purchase_plan.admin_name}}</el-col>
          </el-row>
      </div> -->
      <!-- <div class="" style="margin-top: 10px; font-size: 14px;">
          <div style="padding-bottom: 20px;">采购产品：</div>
          <div class="">
              <el-table :data="products" style="width: 100%" >
                <el-table-column label="产品编号">
                    <template v-slot="scope">
                      {{scope.row.product_no}}
                    </template>
</el-table-column>
<el-table-column label="品名">
  <template v-slot="scope">
                      {{scope.row.product_name}}
                    </template>
</el-table-column>
<el-table-column label="助记码">
  <template v-slot="scope">
                      {{scope.row.mnemonic_code}}
                    </template>
</el-table-column>
<el-table-column label="型号规格">
  <template v-slot="scope">
                      {{scope.row.model}}
                    </template>
</el-table-column>
<el-table-column label="生产厂家">
  <template v-slot="scope">
                      {{scope.row.manufacturer}}
                    </template>
</el-table-column>
</el-table>
</div>
</div> -->


      <div class="con_title"><span></span> 入库信息</div>
      <div style="line-height: 24px; font-size: 14px;">
        <el-row :gutter="20" style="margin-bottom: 10px;">
          <el-col :span="6">开票日期：{{ data.purchase_storage_date }}</el-col>
          <el-col :span="6">单据编号：{{ data.purchase_storage_no }}</el-col>
          <el-col :span="6">保管员：{{ data.keeper_name }}</el-col>
        </el-row>

        <div class="" style="margin-top: 10px; font-size: 14px;">
          <div style="padding-bottom: 20px;">入库产品：</div>
          <div class="">
            <el-table :data="list" style="width: 100%">
              <el-table-column label="产品编号">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.product_no }}
                </template>
              </el-table-column>
              <el-table-column label="品名">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.product_name }}
                </template>
              </el-table-column>
              <el-table-column label="仓库货架">
                <template v-slot="scope">
                  {{ scope.row.warehouse_name }}
                </template>
              </el-table-column>
              <el-table-column label="型号规格">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.model }}
                </template>
              </el-table-column>
              <el-table-column label="生产厂家">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.manufacturer }}
                </template>
              </el-table-column>
              <el-table-column label="单位">
                <template v-slot="scope">
                  {{ scope.row.pack_unit_name }}
                </template>
              </el-table-column>
              <el-table-column label="数量">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.product_num }}
                </template>
              </el-table-column>
              <el-table-column label="单价">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.product_price }}
                </template>
              </el-table-column>
              <el-table-column label="金额">
                <template v-slot="scope">
                  {{ (parseFloat(scope.row.purchase_acceptance_product.product_price) *
                    parseFloat(scope.row.purchase_acceptance_product.product_num)
                    || 0).toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column label="产品批号">
                <template v-slot="scope">
                  {{ scope.row.batch_number }}
                </template>
              </el-table-column>
              <el-table-column label="有效期至">
                <template v-slot="scope">
                  {{ scope.row.valid_until || '长效期' }}
                </template>
              </el-table-column>
              <el-table-column label="注册证名">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_name }}
                </template>
              </el-table-column>
              <el-table-column label="注册证号">
                <template v-slot="scope">
                  {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_no }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>


    <el-card style="margin-bottom: 15px;">
      <div class="con_title"><span></span> 退单信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="单据编号前缀" prop="no">
          <el-select v-model="form.no" placeholder="请选择" style="width: 300px; margin-right: 15px;" filterable>
            <el-option :label="item.value + '__' + item.title" v-for="(item, index) in zjlist" :key="index"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退单日期：" prop="purchase_return_date">
          <el-date-picker v-model="form.purchase_return_date" type="date" value-format="YYYY-MM-DD" placeholder="选择日期"
            style="width:300px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="保管员:" prop="admin_id">
          <el-select v-model="form.admin_id" placeholder="请选择" style="width:300px" filterable>
            <el-option :label="item.full_name + '(' + item.department_name + ')' + '-' + item.mnemonic_code" v-for="(item, index) in rylist"
              :key="index" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" style="width:300px" />
        </el-form-item>
        <el-form-item label="退单类型：">
          <el-radio-group v-model="form.type">
            <el-radio label="1">整单退回</el-radio>
            <el-radio label="2">部分退回</el-radio>
          </el-radio-group>
        </el-form-item>

        <div class="table_Box" v-if="form.type == 2">
          <el-table :data="have_sele_arr" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="产品编号">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.product_no }}
              </template>
            </el-table-column>
            <el-table-column label="品名">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.product_name }}
              </template>
            </el-table-column>
            <el-table-column label="仓库货架">
              <template v-slot="scope">
                {{ scope.row.warehouse_name }}
              </template>
            </el-table-column>
            <el-table-column label="型号规格">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.model }}
              </template>
            </el-table-column>
            <el-table-column label="生产厂家">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.manufacturer }}
              </template>
            </el-table-column>
            <el-table-column label="单位">
              <template v-slot="scope">
                {{ scope.row.pack_unit_name }}
              </template>
            </el-table-column>
            <el-table-column label="库存">
              <template v-slot="scope">
                {{ scope.row.stock }}
              </template>
            </el-table-column>
            <el-table-column label="数量" width="150">
              <template v-slot="scope">
                <div>
                  <el-input-number v-model="scope.row.product_num" controls-position="right" :min="0"
                    size="small"></el-input-number>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template v-slot="scope">
                {{ scope.row.product_price }}
              </template>
            </el-table-column>
            <el-table-column label="金额">
              <template v-slot="scope">
                <div style="color: #FF5115;">
                  {{ (scope.row.product_num * scope.row.product_price || 0).toFixed(3) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品批号">
              <template v-slot="scope">
                {{ scope.row.batch_number }}
              </template>
            </el-table-column>
            <el-table-column label="有效期至">
              <template v-slot="scope">
                {{ scope.row.valid_until || '长效期' }}
              </template>
            </el-table-column>
            <el-table-column label="注册证名">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_name }}
              </template>
            </el-table-column>
            <el-table-column label="注册证号">
              <template v-slot="scope">
                {{ scope.row.purchase_acceptance_product.purchase_plan_product.product.register_cert_no }}
              </template>
            </el-table-column>

            <!-- <el-table-column fixed="right" label="操作">
              <template v-slot="scope">
                <el-button type="text" size="small" @click="handleDelete(scope.$index)">删除</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>

      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addpurchaseAccept",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
        type: '1'
      },
      rules: {
        purchase_return_date: [
          { required: true, message: "退单日期不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "编号前缀不能为空", trigger: "blur" }
        ],
        admin_id: [
          { required: true, message: "保管员不能为空", trigger: "blur" }
        ],
        warehouse_id: [
          { required: true, message: "货位仓库不能为空", trigger: "blur" }
        ]
      },
      zjlist: [],
      rylist: [],
      hwlist: [],
      list: '',
      data: '',
      purchase_plan: '',
      products: '',
      have_sele_arr: [],
      multiple:[]
    };
  },
  created() {
    this.id = this.$route.query.id || '';
    this.get_data();

    this.form.purchase_return_date = this.today_val()
    this.getOptions();
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.multiple = selection
    },
    /** 删除按钮操作 */
    handleDelete(index) {
      const _this = this;
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this.have_sele_arr.splice(index, 1)
      })
    },

    /** 提交按钮 */
    submitForm: function (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {

          if (form == 'form') {
            let _data = { ...this.form };
            _data.purchase_storage_id = this.id;

            if (_data.type == 2) {
              let proarr = [];
              if (this.multiple.length == 0) {
                this.$message.error('请选择退货产品');
                return false;
              }
              for (let i = 0; i < this.multiple.length; i++) {
                let _item = this.multiple[i];
                if (!_item.product_num) {
                  this.$message.error('请完善产品数量');
                  return false;
                } else {
                  proarr.push({
                    purchase_storage_product_id: _item.id,
                    product_num: _item.product_num,
                    product_price: _item.product_price
                  })
                }
              }
              _data.purchase_storage_array = proarr
            }
            this.is_ajax = true;
            this.$httpPost("/backend/PurchaseReturn/save", _data).then((res) => {
              if (res.status == 200) {
                this.$message.success('新增成功');
                this.$router.go(-1)
              } else {
                this.$message.error(res.message);
              }
              this.is_ajax = false;
            }).catch((err) => {
              console.log(err);
              this.is_ajax = false;
            });
          }
        }
      });
    },
    get_data() {
      this.$httpGet("/backend/PurchaseStorage/read", { id: this.id }).then((res) => {
        if (res.status == 200) {
          this.data = res.data;
          // this.products=[res.data.purchase_storage_product[0].purchase_acceptance_product.purchase_plan_product.product]
          // this.products[0].product_price=parseFloat(res.data.purchase_storage_product[0].purchase_acceptance_product.purchase_plan_product.product_price);

          this.purchase_plan = res.data.purchase_acceptance.purchase_plan;
          // this.products[0].id=res.data.purchase_storage_product[0].id;
          // this.have_sele_arr=[...this.products]
          this.list = res.data.purchase_storage_product;
          let arr = [];
          for (let i = 0; i < res.data.purchase_storage_product.length; i++) {
            if (res.data.purchase_storage_product[i].stock != 0) {
              arr.push(res.data.purchase_storage_product[i])
            }
          }
          this.have_sele_arr = arr;
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    getOptions() {
      this.$httpGet("/backend/SysConfig/index", {
        size: 10000000,
        groups: '采购退回'
      }).then((res) => {
        if (res.status == 200) {
          this.zjlist = res.data.data;
          if (this.zjlist.length > 0) {
            this.form.no = this.zjlist[0].value
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
      this.$httpGet("/backend/sys.Admin/keeperList", { size: 10000000 }).then((res) => {
        if (res.status == 200) {
          this.rylist = res.data.data;
          // 默认选中第一个
          // if(this.rylist.length>0){
          //     this.form.admin_id=this.rylist[0].id
          // }
        } else {
          this.$message.error(res.message);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;

  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }

  .el-table {
    margin: 30px 0;
  }
}

.top_box {
  display: flex;
  justify-content: space-around;
}

.examin {
  display: flex;
  justify-content: space-between;
}
</style>
